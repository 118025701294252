





















import { defineComponent, getCurrentInstance } from '@vue/composition-api'

import { CheckProps } from './Check.contracts'
import { customCheckProps, useCheck } from './Check.hooks'

/**
 * Component capable to render Checkbox or Radio element.
 * @displayName Check
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
export const Check = defineComponent({
  name: 'CustomCheck',
  props: customCheckProps,

  setup (props: CheckProps) {
    return useCheck(props, getCurrentInstance())
  }
})
export default Check

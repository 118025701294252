// Copyright © 2022 Move Closer

import { ComponentInternalInstance, toRef } from '@vue/composition-api'

import { ComponentObjectPropsOptions } from '../../vue-api'
import { FormControlModelType } from '../../abstract/general'

import {
  getAbstractCheckControlProps,
  useCheckControl
} from '../../abstract/components/CheckControl/CheckControl.hooks'
import { useErrors, useThemeClass } from '../../composables'

import { CheckProps, CheckTheme, UseCheckProvides } from './Check.contracts'
import { themeClassRegistry } from '../Button/Button.config'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
export const customCheckProps: ComponentObjectPropsOptions<CheckProps> = {
  ...getAbstractCheckControlProps<FormControlModelType>(),
  /**
   * Check's theme (style variant).
   */
  theme: {
    type: String,
    required: false,
    default: CheckTheme.Primary
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const useCheck = (props: CheckProps, internalInstance: ComponentInternalInstance | null): Omit<UseCheckProvides<string | number | boolean>, 'isActive'> => {
  if (internalInstance === null) {
    throw new Error('useLink(): FATAL ERROR! Failed to resolve the component instance!')
  }

  const { clearErrors, hasErrors } = useErrors(toRef(props, 'errors'), internalInstance.emit)
  const { checked, checkType, checkValue } = useCheckControl(props, internalInstance.emit, clearErrors)

  const { type } = internalInstance
  const theme = toRef(props, 'theme')

  const { themeClass } = useThemeClass<string>(theme, themeClassRegistry, `${type.componentTag}`)

  return { checked, checkType, checkValue, hasErrors, themeClass }
}
